import ChangePassword from "./ChangePassword";
export default {
  name: 'AppMain',
  components: {
    ChangePassword: ChangePassword
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  watch: {
    // 检测路由变化缓存页面
    $route: function $route() {
      var name = this.$route.name;
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route);
      }
    }
  }
};