import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import Element from 'element-ui';
import Cookies from 'js-cookie';
import * as filters from "../filters"; // global filters
import permission from '@/directive/permission/index.js';
import '@/utils/lodash';
import "../permission";
import "../icons"; // icon
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import { resetForm } from '@/utils/utils';
import '@wangeditor/editor/dist/css/style.css';
export default {
  install: function install(Vue) {
    Vue.use(Element, {
      size: Cookies.get('size') || 'medium'
    });

    // 全局组件
    Vue.component('ImgPreview', function () {
      return import('@/components/ImagePreview');
    });
    Vue.component('Pagination', function () {
      return import('@/components/Pagination');
    });
    // Vue.component('ImagePreview', () => import('@/components/ImagePreview'))
    Vue.component('XXImage', function () {
      return import('@/components/Image');
    });
    Vue.component('TableFilter', function () {
      return import('@/components/Filter/src');
    });
    Vue.component('SrmTable', function () {
      return import('@/components/SrmTable/src');
    });
    Vue.component('TDatePicker', function () {
      return import('@/components/TDatePicker');
    });

    // 权限
    Vue.use(permission);

    // 过滤器
    Object.keys(filters).forEach(function (key) {
      Vue.filter(key, filters[key]);
    });

    // 全局方法
    Vue.prototype.resetForm = resetForm;
  }
};