import "core-js/modules/es6.array.find";
import Lodash from 'lodash';
import Vue from 'vue';
/**
 * 自定义设置筛选框默认值函数
 * @param {Arr} configArr // 需要处理的配置数据
 * @param {Object} matchesProperty //处理目标对象的唯一字段
 * @param {any} inserValue // 输入的值
 * @param {String} targetKey // 默认给options赋值 可不传
 */
function setDefaultValue() {
  var configArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var matchesProperty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var inserValue = arguments.length > 2 ? arguments[2] : undefined;
  var targetKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'options';
  // const Target = Lodash.find(configArr, matchesProperty)
  // if (!Target || !Target.hasOwnProperty(targetKey)) {
  //   throw new Error('error targetKey', 'setDefaultValue')
  // }
  Lodash.find(configArr, matchesProperty)[targetKey] = inserValue;
}
Lodash.setDefaultValue = setDefaultValue;
Vue.prototype._ = Lodash;