import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/Users/fangqing/dataList/third/zll-admin/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (!time) return;
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 *
 * @param {发货状态} v
 */
export function extendsStatu(v) {
  switch (v) {
    case 0:
      return '';
    case 1:
      return '卖家已发货，待平台签收';
    case 2:
      return '平台已签收';
    case 3:
      return '鉴定通过，待平台发货';
    case 4:
      return '平台已发货';
    case 5:
      return '鉴定有瑕疵，待买家确认';
  }
}

/**
 *
 * @param {订单状态} v
 */
export function orderStatusFilter(v) {
  switch (v) {
    case 0:
      return '待付款';
    case 1:
      return '待发货';
    case 2:
      return '已发货';
    case 3:
      return '交易成功';
    case 4:
      return '交易关闭';
  }
}
export function cancelReasonFilter(v) {
  switch (v) {
    case 'PAYMENT_TIMEOUT':
      return '卖家未支付';
    case 'SELLER_NOT_DELIVER':
      return '卖家未发货';
    case 'SELLER_FALSE_DELIVERY':
      return '卖家虚假发货';
    case 'USER_CANCEL_TOTAL':
      return '买家取消';
    case 'REJECT_FLAW':
      return '买家不接受瑕疵';
    case 'QUALITY_INSPECTION_FAILED':
      return '质检未通过';
    case 'IDENTIFICATION_FAILED':
      return '鉴定未通过';
    case 'USER_RETURN_GOODS':
      return '买家退货';
    case 'WAREHOUSE_REJECTION':
      return '平台拒收';
  }
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
/**
 * 获取当前环境的地址前缀
 */
export function getEnvPrefix() {
  var env = process.env.NODE_ENV;
  console.log(env, 'env');
  var ret = '';
  switch (env) {
    case 'beta':
      ret = 'ystest-h5';
      break;
    case 'development':
      ret = 'h5-dev';
      break;
    case 'production':
      ret = 'h5';
      break;
  }
  return ret;
}
export var currentBrower = function myBrowser() {
  var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
  console.log(userAgent, 'userAgent');
  var isOpera = userAgent.indexOf('Opera') > -1;
  if (isOpera) {
    // 判断是否Opera浏览器
    return 'Opera';
  }
  if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera || userAgent.indexOf('Edge') > -1) {
    // 判断是否IE浏览器
    return 'IE';
  }
  if (userAgent.indexOf('Firefox') > -1) {
    // 判断是否Firefox浏览器
    return 'FF';
  }
  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome';
  }
  if (userAgent.indexOf('Safari') > -1) {
    // 判断是否Safari浏览器
    return 'Safari';
  }
};
export function exportExcelFile(res) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var blob = new Blob([res.data]);
  var downloadElement = document.createElement('a');
  var href = window.URL.createObjectURL(blob);
  if (!res) {
    console.warn('没有二进制流文件');
  }
  fileName = res && res.headers['content-disposition'] ? decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]) : fileName;
  try {
    if (currentBrower() === 'IE') {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    }
  } catch (e) {
    console.log(e, 'error export');
  }
}

/**
 * 金额元转分
 * @param num 金额。
 * @param precision 精度。默认为12，一般选12就能解决掉大部分0001和0009问题，而且大部分情况下也够用了，如果你需要更精确可以调高。
 * @returns {number}
 */
export function yuanToFen(num) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 12;
  return +parseFloat((num * 100).toPrecision(precision));
}

/**
 * 金额分转元
 * @param num 金额。
 * @param precision 精度。默认为12，一般选12就能解决掉大部分0001和0009问题，而且大部分情况下也够用了，如果你需要更精确可以调高。
 * @returns {number}
 */
export function fenToYuan(price) {
  var digit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var ret;
  if (!price && price !== 0) {
    return '';
  } else {
    ret = price / 100;
  }
  return ret.toFixed(digit);
}
/**
 * 金额分转元
 * @param number 数字
 * @param symbol 符号
 * @returns {number}
 */
// 处理数值的正负号
export function symbolProcess(number) {
  var symbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var digit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var Num = Number(number);
  if (isNaN(Num)) {
    throw new Error('Not a Number!');
  }
  Num = Num === 0 ? Num : Num.toFixed(digit); // 0的时候不加符号
  if (symbol && Num !== 0) {
    Num = "".concat(symbol).concat(Num);
  } else {
    Num = Num > 0 ? "+".concat(Num) : Num;
  }
  return Num;
}

// 禁止背景层滚动
export var ModalHelper = function (bodyCls) {
  var scrollTop;
  return {
    afterOpen: function afterOpen() {
      scrollTop = document.scrollingElement.scrollTop;
      document.body.classList.add(bodyCls);
      document.body.style.top = -scrollTop + 'px';
    },
    beforeClose: function beforeClose() {
      document.body.classList.remove(bodyCls);
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop;
    }
  };
}('modal-open');

// 优雅处理catch
export var promiseWrap = function promiseWrap(promise) {
  return promise.then(function (data) {
    return [null, data];
  }).catch(function (error) {
    return [error, null];
  });
};
// table中为空字段占位符
export var emptyPlaceholder = function emptyPlaceholder(value) {
  return value === '' || value === null || value === undefined ? '-' : value;
};