import "core-js/modules/es6.regexp.search";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-search",
    class: {
      show: _vm.show
    }
  }, [_c("svg-icon", {
    attrs: {
      "class-name": "search-icon",
      "icon-class": "search"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.click.apply(null, arguments);
      }
    }
  }), _c("el-select", {
    ref: "headerSearchSelect",
    staticClass: "header-search-select",
    attrs: {
      "remote-method": _vm.querySearch,
      filterable: "",
      "default-first-option": "",
      remote: "",
      placeholder: "输入菜单可查询"
    },
    on: {
      change: _vm.change
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.path,
      attrs: {
        value: item,
        label: item.title.join(" > ")
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };