import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.starts-with";
import pathToRegexp from 'path-to-regexp';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return;
      }
      // if (route.meta.routers) {
      //   this.levelList = route.meta.routers
      //   return
      // }
      this.getBreadcrumb();
    }
  },
  created: function created() {
    // if (this.$route.meta.routers) {
    //   this.levelList = this.$route.meta.routers
    //   return
    // }
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      // 非父子级路由指定父级面包屑title和跳转路径
      var hasRedirectTo = this.hasRedirectTo();
      // 判断是否是三级以上面包屑 三级以上的面包屑通过手动配置进行插入
      var hasConfigBreadcrumb = this.hasConfigBreadcrumb();
      if (hasRedirectTo) {
        var path = hasRedirectTo.path,
          title = hasRedirectTo.title;
        matched.splice(1, 0, {
          path: path,
          meta: {
            title: title
          }
        });
      } else if (hasConfigBreadcrumb) {
        this.levelList = hasConfigBreadcrumb;
        return;
      }
      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    // 判断是否有 非父子级路由指定父级面包屑title和跳转路径
    hasRedirectTo: function hasRedirectTo() {
      var meta = this.$route.meta;
      if (meta && meta.redirectTo) {
        return meta.redirectTo;
      } else {
        return false;
      }
    },
    // 判断是否是三级以上面包屑 三级以上的面包屑通过手动配置进行插入
    hasConfigBreadcrumb: function hasConfigBreadcrumb() {
      var meta = this.$route.meta;
      if (meta && meta.routers) {
        return meta.routers;
      } else {
        return false;
      }
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      // 多级面包屑 手动配置的
      if (!item.meta) {
        if (item.params) {
          if (item.query) {
            var params = this.dealParams(item.query);
            this.$router.push("".concat(item.path, "/").concat(sessionStorage.getItem(item.params), "?").concat(params));
          } else {
            this.$router.push("".concat(item.path, "/").concat(sessionStorage.getItem(item.params)));
          }
        } else {
          if (item.query) {
            var _params = this.dealParams(item.query);
            this.$router.push("".concat(item.path, "?").concat(_params));
          } else {
            this.$router.push(item.path);
          }
        }
        return;
      }
      // 根据路由自动生成的
      var redirect = item.redirect,
        path = item.path;
      var meta = this.$route.meta;
      if (redirect) {
        if (meta && meta.parentRoute) {
          this.$router.push(meta.parentRoute.path);
          return;
        }
        if (meta && meta.redirectTo) {
          this.$router.push(meta.redirectTo.path);
          return;
        }
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
    dealParams: function dealParams(arr) {
      var isArray = Array.isArray(arr);
      var str = '';
      if (isArray) {
        arr.forEach(function (ele) {
          if (!sessionStorage.getItem(ele)) return;
          str += "".concat(ele, "=").concat(sessionStorage.getItem(ele), "&");
        });
      }
      return str.slice(0, -1);
    }
  }
};