import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var UserInfoKey = 'zhe-userInfo';
var Invoice = 'invoice';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getUserInfo() {
  return JSON.parse(sessionStorage.getItem(UserInfoKey));
}
export function setUserInfo(userInfo) {
  return sessionStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
}
export function setInvoiceInfo(invoice) {
  return sessionStorage.setItem(Invoice, JSON.stringify(invoice));
}
export function getInvoiceInfo() {
  return JSON.parse(sessionStorage.getItem(Invoice));
}
export function removeUserInfo() {
  return sessionStorage.removeItem(UserInfoKey);
}