import axios from 'axios';
import { Loading, Notification, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import router from "../router/index";
import qs from 'qs';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: process.env.VUE_APP_SERVE === 'BETA' ? '/api' : process.env.VUE_APP_BASE_API,
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 60000 // request timeout
});

service.interceptors.request.use(function (config) {
  config.loading = config.showScreenLoading && Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  if (store.getters.token) {
    config.headers['Authorization'] = "Bearer ".concat(getToken());
  }
  var contentType = config.headers['Content-Type'];
  if (/x-www-form-urlencoded/.test(contentType)) {
    config.data = qs.stringify(config.data);
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  var data = response.data,
    config = response.config;
  var code = data.code;
  config.loading && config.loading.close();
  if (config.export) return data;
  if (code !== 200) {
    switch (code) {
      case 403:
        router.app.$router.push({
          path: '/401'
        });
        break;
      case 401:
        MessageBox.confirm('您的登录已失效，重新登陆？', '提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          store.dispatch('user/resetToken').then(function () {
            location.reload();
          });
        });
        break;
      default:
        Notification({
          title: '提示',
          message: data.msg || 'Error',
          type: 'error',
          showClose: false
        });
        break;
    }
    return Promise.reject(new Error(data.msg || 'Error'));
  } else {
    return data;
  }
}, function (error) {
  // eslint-disable-next-line no-undef
  var message = _.get(error, 'response.data.msg');
  Notification({
    message: message || '请求出错',
    type: 'error'
  });
  return Promise.reject(error);
});
export default service;