import Layout from '@/layout';
export default {
  path: '/event',
  component: Layout,
  redirect: 'noRedirect',
  alwaysShow: true,
  meta: {
    title: '事件管理',
    icon: 'event',
    roles: ['event']
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/event/list');
    },
    name: 'EventList',
    meta: {
      title: '事件列表',
      noCache: false,
      roles: ['event:eventType:list']
    }
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/event/detail');
    },
    name: 'EventDetail',
    meta: {
      noCache: true,
      title: '事件详情',
      activeMenu: '/event/list',
      redirectTo: {
        title: '事件列表',
        path: '/event/list'
      }
    },
    hidden: true
  }, {
    path: 'role',
    component: function component() {
      return import('@/views/event/role');
    },
    name: 'EventRole',
    meta: {
      title: '演练角色管理',
      roles: ['event:role:list']
    }
  }]
};