import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';
import { getUserInfo } from '@/utils/auth';
export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var roles = store.getters && store.getters.roles;
    var _getUserInfo = getUserInfo(),
      role = _getUserInfo.roles;
    if (!role.includes('admin')) {
      if (value && value instanceof Array && value.length > 0) {
        var permissionRoles = value;
        var hasPermission = roles.some(function (role) {
          return permissionRoles.includes(role);
        });
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      } else {
        throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
      }
    }
  }
};