// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/logo_homebg@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-6494804b]:export {\n  menuText: #B4B9C8;\n  menuActiveText: #fff;\n  subMenuActiveText: #fff;\n  subMenuActiveBg: #626A84;\n  menuBg: #3A4158;\n  menuHover: #626A84;\n  subMenuBg: #343A4F;\n  subMenuHover: #626A84;\n  sideBarWidth: 220px;\n  warning: #F57474;\n}\n.sidebarLogoFade-enter-active[data-v-6494804b] {\n  transition: opacity 1.5s;\n}\n.sidebarLogoFade-enter[data-v-6494804b],\n.sidebarLogoFade-leave-to[data-v-6494804b] {\n  opacity: 0;\n}\n.sidebar-logo-container[data-v-6494804b] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  position: relative;\n  width: 100%;\n  height: 64px;\n  line-height: 64px;\n  padding: 0;\n  text-align: center;\n  overflow: hidden;\n}\n.sidebar-logo-container.collapse[data-v-6494804b] {\n  padding: 0;\n  text-align: center;\n}\n.sidebar-logo-container .sidebar-logo-link[data-v-6494804b] {\n  height: 100%;\n  width: 100%;\n}\n.sidebar-logo-container .sidebar-logo-link .sidebar-logo[data-v-6494804b] {\n  width: 32px;\n  height: 32px;\n  vertical-align: middle;\n  margin-right: 12px;\n  border-radius: 6px;\n}\n.sidebar-logo-container .sidebar-logo-link .sidebar-title[data-v-6494804b] {\n  display: inline-block;\n  margin: 0;\n  color: #00feff;\n  font-weight: 500;\n  line-height: 64px;\n  font-size: 16px;\n  vertical-align: middle;\n}\n.sidebar-logo-container.collapse .sidebar-logo[data-v-6494804b] {\n  margin-right: 0px;\n}", ""]);
// Exports
module.exports = exports;
