import Layout from '@/layout';
export default {
  path: '/question',
  component: Layout,
  redirect: 'noRedirect',
  alwaysShow: true,
  meta: {
    title: '题库管理',
    icon: 'question',
    roles: ['question']
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/question/list');
    },
    name: 'QuestionList',
    meta: {
      title: '题库管理',
      roles: ['question:questionType:list']
    }
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/question/detail');
    },
    name: 'QuestionDetail',
    meta: {
      noCache: true,
      title: '创建题库',
      activeMenu: '/question/list',
      redirectTo: {
        title: '题库管理',
        path: '/question/list'
      }
    },
    hidden: true
  }]
};