var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-breadcrumb", {
    staticClass: "app-breadcrumb",
    attrs: {
      separator: "/"
    }
  }, [_c("transition-group", {
    attrs: {
      name: "breadcrumb"
    }
  }, _vm._l(_vm.levelList, function (item, index) {
    return _c("el-breadcrumb-item", {
      key: "".concat(index, "-").concat(item)
    }, [item.redirect === "noRedirect" || index == _vm.levelList.length - 1 || !item.path ? _c("span", {
      staticClass: "no-redirect"
    }, [_vm._v(_vm._s(item.meta && item.meta.title || item.title))]) : _c("a", {
      on: {
        click: function click($event) {
          $event.preventDefault();
          return _vm.handleLink(item);
        }
      }
    }, [_vm._v(_vm._s(item.meta && item.meta.title || item.title))])]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };