var state = {
  planId: ''
};
var mutations = {
  SET_PLAN_ID: function SET_PLAN_ID(state, value) {
    state.planId = value;
  }
};
var actions = {};
var getters = {
  getPlanId: function getPlanId(state) {
    return state.planId;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};