import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import Layout from '@/layout';
import system from "./modules/system";
import event from "./modules/event";
import drill from "./modules/drill";
import question from "./modules/question";
// import bigScreen from './modules/bigScreen'

export var constantRoutes = [{
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  // hidden: true,
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'home',
      affix: true
    }
  }]
}];
export var asyncRoutes = [drill, event, question, system,
// bigScreen,
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;