import Cookies from 'js-cookie';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  imagePreview: false,
  imagePreviewUrl: ''
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  OPEN_SIDEBAR: function OPEN_SIDEBAR(state, opened, withoutAnimation) {
    if (opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
    state.sidebar.opened = opened;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    Cookies.set('size', size);
  },
  TOGGLE_IMAGE_PREVIEW: function TOGGLE_IMAGE_PREVIEW(state, imagePreview) {
    if (imagePreview) {
      state.imagePreviewUrl = imagePreview;
      state.imagePreview = true;
    } else {
      state.imagePreview = false;
    }
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var opened = _ref3.opened,
      withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', opened, withoutAnimation);
  },
  openSideBar: function openSideBar(_ref4, _ref5) {
    var commit = _ref4.commit;
    var opened = _ref5.opened,
      withoutAnimation = _ref5.withoutAnimation;
    commit('OPEN_SIDEBAR', opened, withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref6, device) {
    var commit = _ref6.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref7, size) {
    var commit = _ref7.commit;
    commit('SET_SIZE', size);
  },
  toggleImagePreview: function toggleImagePreview(_ref8, _ref9) {
    var commit = _ref8.commit;
    var _ref9$url = _ref9.url,
      url = _ref9$url === void 0 ? '' : _ref9$url;
    commit('TOGGLE_IMAGE_PREVIEW', url);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};