import request from '@/utils/request';
// 用户登录管理后台
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  });
}

// 用户登出管理后台
export function logout() {
  return request({
    url: '/shoes-seller-manager/auth/logout',
    method: 'Delete'
  });
}
// 用户修改管理后台登录密码
export function updatePersonal(data) {
  // const data = {
  //   oldPassword: encrypt(val.oldPassword),
  //   newPassword: encrypt(val.newPassword),
  //   newPasswordAgain: encrypt(val.newPasswordAgain),
  //   type: val.type
  // }
  return request({
    url: '/shoes-seller-manager/personInfo/update',
    method: 'post',
    data: data
  });
}
export function getCaptchaImage(data) {
  return request({
    url: '/captchaImage',
    method: 'get',
    data: data
  });
}