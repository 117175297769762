import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
// import parseTime, formatTime and set to filter
import { parseTime } from '@/utils';
export function isExceedChara(str, L) {
  console.log(str, 'str');
  console.log(L, 'L');
  var chrlen = String(str).replace(/[^\x00-\xff]/g, '**').length; // 字节长度
  console.log(chrlen, L, 'chrlen');
  if (chrlen <= L) {
    return false;
  }
  return true;
}
export function formatTimeFilter(val, format) {
  try {
    if (val) {
      var time = parseTime(val, format);
      return time;
    } else return;
  } catch (err) {
    console.log(err);
  }
}
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  var si = [{
    value: 1e18,
    symbol: 'E'
  }, {
    value: 1e15,
    symbol: 'P'
  }, {
    value: 1e12,
    symbol: 'T'
  }, {
    value: 1e9,
    symbol: 'G'
  }, {
    value: 1e6,
    symbol: 'M'
  }, {
    value: 1e3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * @param {String} str
 * @param {Number} L //字节长度 一个汉字=2字节
 */
export function cutStr(str, L) {
  var result = '';
  var strlen = String(str).length; // 字符串长度
  var chrlen = String(str).replace(/[^\x00-\xff]/g, '**').length; // 字节长度

  if (chrlen <= L) {
    return str;
  }
  for (var i = 0, j = 0; i < strlen; i++) {
    var chr = str.charAt(i);
    if (/[\x00-\xff]/.test(chr)) {
      j++; // ascii码为0-255，一个字符就是一个字节的长度
    } else {
      j += 2; // ascii码为0-255以外，一个字符就是两个字节的长度
    }

    if (j <= L) {
      // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
      result += chr;
    } else {
      // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
      return result + '...';
    }
  }
}

/**
 *
 * @param {String} str
 * @param {Number} L //字节长度 一个汉字=2字节 不加省略号
 */
export function substr(str, L) {
  var result = '';
  var strlen = String(str).length; // 字符串长度
  var chrlen = String(str).replace(/[^\x00-\xff]/g, '**').length; // 字节长度

  if (chrlen <= L) {
    return str;
  }
  for (var i = 0, j = 0; i < strlen; i++) {
    var chr = str.charAt(i);
    if (/[\x00-\xff]/.test(chr)) {
      j++; // ascii码为0-255，一个字符就是一个字节的长度
    } else {
      j += 2; // ascii码为0-255以外，一个字符就是两个字节的长度
    }

    if (j <= L) {
      // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
      result += chr;
    } else {
      // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
      return result;
    }
  }
}
/**
 *
 * @param {Number} price 单位 分
 * @param {Number} digit 小数点后保留位数
 */
export var priceFilter = function priceFilter(price) {
  var digit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var ret;
  if (!price) {
    ret = 0;
  } else {
    ret = price / 100;
  }
  return ret.toFixed(digit);
};
export var operateRes = function operateRes(value) {
  if (value) {
    if (value === 1) {
      return '投诉成立';
    } else {
      return '撤销投诉';
    }
  } else {
    return '未操作';
  }
};
export var unstatus = function unstatus(value) {
  switch (value) {
    case 0:
      return '商家申请下架';
    case -2:
      return '小象下架';
    case -1:
      return '审核未通过';
  }
};
export var rationStatus = function rationStatus(value) {
  switch (value) {
    case 0:
      return '恢复到待审核';
    case 1:
      return '审核通过';
    case -1:
      return '审核不通过';
    case -2:
      return '下架';
    case 2:
      return '警告';
    case 3:
      return '冻结资金';
    case 4:
      return '解冻资金';
    case 5:
      return '撤销警告';
  }
};
export var sualStatus = function sualStatus(status) {
  switch (status) {
    case 0:
      return '商家申请关闭';
    case 1:
      return '正常';
    case 2:
      return '已整改';
    case 3:
      return '被举报';
    default:
      return '已警告';
  }
};
export var sualStatusClass = function sualStatusClass(status) {
  switch (status) {
    case 0:
      return 'none';
    case 1:
      return 'none';
    case 2:
      return 'greenIcon';
    case 3:
      return 'dangerIcon';
    default:
      return 'warnIcon';
  }
};
/**
 * @param {String} StatusMinute
 * 转换时间
 */
export function timeStamp(StatusMinute) {
  var day = parseInt(StatusMinute / 60 / 24);
  var hour = parseInt(StatusMinute / 60 % 24);
  var min = parseInt(StatusMinute % 60);
  StatusMinute = '';
  if (day > 0) {
    StatusMinute = day + '天';
  }
  if (hour > 0) {
    StatusMinute += hour + '小时';
  }
  if (min > 0) {
    StatusMinute += parseFloat(min) + '分钟';
  }
  return StatusMinute;
}

/**
 *
 * @param {订单状态} status
 */
export function returnOrderStatus(status) {
  switch (status) {
    case 0:
      return '待处理';
    case 1:
      return '备货中';
    case 2:
      return '已发货';
    case 3:
      return '已发货';
    case 4:
      return '已完成';
    case 5:
      return '已取消';
    case 6:
      return '已取消';
  }
}

/**
 *
 * @param {表格字段数据} value
 */
export function returnNullTableData(value) {
  return value || '-';
}