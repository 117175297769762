import Layout from '@/layout';
export default {
  path: '/system',
  component: Layout,
  redirect: 'noRedirect',
  name: 'System',
  alwaysShow: true,
  meta: {
    title: '系统管理',
    icon: 'system',
    roles: ['system']
  },
  children: [{
    path: 'user',
    component: function component() {
      return import('@/views/system/user');
    },
    name: 'User',
    meta: {
      title: '用户管理',
      roles: ['system:user:list']
    }
  }, {
    path: 'role',
    component: function component() {
      return import('@/views/system/role');
    },
    name: 'Role',
    meta: {
      title: '角色管理',
      roles: ['system:role:list']
    }
  }, {
    path: 'menu',
    component: function component() {
      return import('@/views/system/menu');
    },
    name: 'Menu',
    meta: {
      title: '菜单管理',
      roles: ['system:menu:list']
    }
  }]
};