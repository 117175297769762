import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/Users/fangqing/dataList/third/zll-admin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { asyncRoutes, constantRoutes } from '@/router';
import { getUserInfo } from '@/utils/auth';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  var _getUserInfo = getUserInfo(),
    role = _getUserInfo.roles;
  if (role.includes('admin')) return true; // 不需要权限控制
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * 去除二级路由 解决多级路由页面缓存问题
 * @param routes asyncRoutes
 * @param prefix
 */
function deleteFakeParent(router, prefix) {
  var newRouter = _objectSpread({}, router);
  if (prefix) newRouter.path = prefix + '/' + router.path;
  if (!router.children) return newRouter;
  var children = [];
  if (router.noCompoent) {
    router.children.forEach(function (route) {
      var item = deleteFakeParent(route, newRouter.path);
      if (Array.isArray(item)) {
        item.forEach(function (el) {
          children.push(el);
        });
      } else {
        children.push(item);
      }
    });
    newRouter = children;
  } else {
    // debugger
    router.children.forEach(function (route) {
      var item = deleteFakeParent(route);
      if (Array.isArray(item)) {
        item.forEach(function (el) {
          children.push(el);
        });
      } else {
        children.push(item);
      }
    });
    newRouter.children = children;
  }
  return newRouter;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: [],
  realRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_REAL_ROUTES: function SET_REAL_ROUTES(state, routes) {
    // state.addRoutes = routes
    state.realRoutes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit('SET_ROUTES', accessedRoutes);
      var dropSecond = accessedRoutes.map(function (router) {
        return deleteFakeParent(router);
      }); // 删除二级
      commit('SET_REAL_ROUTES', dropSecond);
      resolve(dropSecond);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};