// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#B4B9C8",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"subMenuActiveBg": "#626A84",
	"menuBg": "#3A4158",
	"menuHover": "#626A84",
	"subMenuBg": "#343A4F",
	"subMenuHover": "#626A84",
	"sideBarWidth": "220px",
	"warning": "#F57474"
};
module.exports = exports;
