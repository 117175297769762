import Layout from '@/layout';
export default {
  path: '/drill',
  component: Layout,
  redirect: 'noRedirect',
  alwaysShow: true,
  meta: {
    title: '演练管理',
    icon: 'drill',
    roles: ['drill']
  },
  children: [{
    path: 'drillList',
    component: function component() {
      return import('@/views/drill/drill');
    },
    name: 'Drill',
    meta: {
      title: '演练列表',
      roles: ['drill:drillType:list']
    }
  }, {
    path: 'file',
    component: function component() {
      return import('@/views/drill/file');
    },
    name: 'File',
    meta: {
      noCache: true,
      title: '方案存档',
      activeMenu: '/drill/drillList',
      redirectTo: {
        title: '题库管理',
        path: '/drill/drillList'
      }
    },
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/drill/create');
    },
    name: 'Create',
    meta: {
      noCache: true,
      title: '创建演练',
      activeMenu: '/drill/drillList',
      redirectTo: {
        title: '演练列表',
        path: '/drill/drillList'
      }
    },
    hidden: true
  }, {
    path: 'export',
    component: function component() {
      return import('@/views/drill/export');
    },
    name: 'export',
    meta: {
      noCache: true,
      title: '方案导出',
      activeMenu: '/drill/drillList',
      redirectTo: {
        title: '演练列表',
        path: '/drill/drillList'
      }
    },
    hidden: true
  }, {
    path: 'host',
    component: function component() {
      return import('@/views/drill/host');
    },
    name: 'host',
    meta: {
      title: '主持人管理',
      roles: ['drill:host:list']
    }
  }]
};