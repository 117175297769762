var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "change-password-dialog"
  }, [_c("el-dialog", {
    attrs: {
      title: "修改密码",
      visible: _vm.show,
      width: "480px",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.show = $event;
      }
    }
  }, [_c("div", {
    staticClass: "marginTop"
  }, [_c("el-form", {
    ref: "loginFrom",
    attrs: {
      "label-position": "left",
      "status-icon": "",
      model: _vm.loginFrom,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "原密码：",
      error: _vm.passwordError,
      prop: "oldPassword",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请输入原密码"
    },
    model: {
      value: _vm.loginFrom.oldPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.loginFrom, "oldPassword", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "loginFrom.oldPassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "新密码：",
      prop: "newPassword",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请输入新密码"
    },
    model: {
      value: _vm.loginFrom.newPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.loginFrom, "newPassword", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "loginFrom.newPassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "确认新密码：",
      prop: "newPasswordAgain",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请再次输入新密码"
    },
    model: {
      value: _vm.loginFrom.newPasswordAgain,
      callback: function callback($$v) {
        _vm.$set(_vm.loginFrom, "newPasswordAgain", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "loginFrom.newPasswordAgain"
    }
  })], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.resetRole("loginFrom");
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitChangePwd("loginFrom");
      }
    }
  }, [_vm._v("确认修改")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };