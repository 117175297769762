import "/Users/fangqing/dataList/third/zll-admin/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/fangqing/dataList/third/zll-admin/node_modules/core-js/modules/es6.promise.js";
import "/Users/fangqing/dataList/third/zll-admin/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/fangqing/dataList/third/zll-admin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import '@/styles/index.scss';
import App from "./App";
import store from "./store";
import router from "./router";
import globalRegister from '@/global';
Vue.use(globalRegister);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});